var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-pages my-5 pt-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-4"},[_c('div',{staticClass:"card overflow-hidden"},[_vm._m(0),_c('div',{staticClass:"card-body p-4"},[_c('div',{staticClass:"p-3"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"success","dismissible":""},model:{value:(_vm.registerSuccess),callback:function ($$v) {_vm.registerSuccess=$$v},expression:"registerSuccess"}},[_vm._v("Registration successfull.")]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.isRegisterError),callback:function ($$v) {_vm.isRegisterError=$$v},expression:"isRegisterError"}},[_vm._v(_vm._s(_vm.regError))]),(_vm.notification.message)?_c('div',{class:'alert ' + _vm.notification.type},[_vm._v(" "+_vm._s(_vm.notification.message)+" ")]):_vm._e(),_c('b-form',{staticClass:"form-horizontal mt-4",on:{"submit":function($event){$event.preventDefault();return _vm.tryToRegisterIn.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"name-group","label":"Nome","label-for":"name","label-class":"form-label"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.name.$error,
                    },attrs:{"id":"name","type":"text","placeholder":"Digite o Nome"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),(_vm.submitted && !_vm.$v.user.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" name is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"email-group","label":"Email","label-for":"email","label-class":"form-label"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.email.$error,
                    },attrs:{"id":"email","type":"email","placeholder":"Digite o Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),(_vm.submitted && _vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v("Email is required.")]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v("Please enter valid email.")]):_vm._e()]):_vm._e()],1),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"password-group","label":"Password","label-for":"password","label-class":"form-label"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.user.password.$error,
                    },attrs:{"id":"password","type":"password","placeholder":"Digite a Senha"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),(_vm.submitted && !_vm.$v.user.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password is required. ")]):_vm._e()],1),_c('div',{staticClass:"form-group mb-0 text-center"},[_c('div',{staticClass:"col-12 text-end"},[_c('b-button',{staticClass:"w-md",attrs:{"type":"submit","variant":"primary"}},[_vm._v("Register")])],1)]),_c('div',{staticClass:"form-group mt-2 mb-0 row"},[_c('div',{staticClass:"col-12 mt-4"},[_c('p',{staticClass:"mb-0"},[_vm._v(" By registering you agree to the Veltrix "),_c('a',{staticClass:"text-primary",attrs:{"href":"#"}},[_vm._v("Terms of Use")])])])])],1)],1)])]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" Already have an account ? "),_c('router-link',{staticClass:"fw-medium text-primary",attrs:{"to":"/login"}},[_vm._v("Login")])],1),_c('p',[_vm._v(" ©"+_vm._s(new Date().getFullYear())+" Veltrix. Crafted with "),_c('i',{staticClass:"mdi mdi-heart text-danger"}),_vm._v(" by Themesbrand ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-primary"},[_c('div',{staticClass:"text-primary text-center p-4"},[_c('h5',{staticClass:"text-white font-size-20"},[_vm._v("Free Register")]),_c('p',{staticClass:"text-white-50"},[_vm._v("Get your free Veltrix account now.")]),_c('a',{staticClass:"logo logo-admin",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo-sm.png"),"height":"24","alt":"logo"}})])])])
}]

export { render, staticRenderFns }